<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-02-08 18:25:32
 * @LastEditTime: 2023-02-27 14:24:45
 * @FilePath: \awx-ui\src\pages\operationConsole\components\bottomOpe\upload.vue
-->
<template>
  <div class="upload-page">
    <!-- :accept="fileType" -->
    <a-upload name="file" :multiple="true" :showUploadList="false" :accept="fileType" @change="handleChange" :remove="handleRemove" :beforeUpload="beforeUpload">
      <div class="upload-item">
        <svg-icon icon-class="ope-local" />
        <span>本地文件</span>
      </div>
    </a-upload>
    <div class="upload-item">
      <svg-icon icon-class="ope-net" />
      <span>网盘文件</span>
    </div>
  </div>
</template>
      
  <script>
import moment from "moment";
export default {
  props: {
    currentTab: String,
    selectedGroupRows: Array
  },
  data() {
    return {
      fileList: [],
      headers: {
        authorization: "authorization-text",
        Authorization: sessionStorage.getItem("token"),
      },
      fileType:
        ".xls, .xlsx, .zip, .txt, .doc, .docx, .dotx, .dot, .pot, .ppt, .pptx",
    };
  },
  inject: ['fileSync'],
  watch: {
    fileList: {
      handler(val) {
        console.log('边变白你')
        this.$emit("getFileList", val);
      },
      deep: true,
    },
  },
  methods: {
    clearFileList () {
      this.fileList = []
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      if (file.size > 10 * 1024 * 1000) {
        this.$message.error("文件上传最大仅支持10M");
        return;
      }
      file.status = "notUpload";
      file.loading = false;
      file.progress = 0
      this.fileList.push(file);
      return false;
    },
    handleChange(e) {
      console.log(e, "ddddd");
      // if (e.file.size > 10 * 1024 * 1000) {
      //   this.$message.error("文件上传最大仅支持10M");
      //   return;
      // }
      // this.fileList = e.fileList
      // this.fileList.forEach(item => {
      //   item.loading = false
      //   item.status = 'notUpload'
      // });
    }
  },
  created() {},
  mounted() {
    this.$EventBus.$on("removeFile", (file) => {
      this.handleRemove(file);
    });
  },
  beforeDestroy() {
    this.$EventBus.$off("removeFile");
  }
};
</script>
      
  <style lang="less" scoped>
.upload-page {
  display: flex;
  flex-direction: column;
  // padding: 10px 20px;
  padding: 10px;
  .upload-item {
    cursor: pointer;
    padding: 5px 20px 5px;
    &:hover {
      background: #e6e8ed;
    }
    svg {
    }
    span {
      font-size: 14px;
      color: #445166;
      letter-spacing: 0;
      font-weight: 400;
      margin-left: 12px;
    }
  }
}
</style>