<template>
  <div style="position: relative">
    <div class="operation-console">
      <!-- <a-spin :spinning="treeSpain" style="height: 100%"> -->
      <a-layout v-if="hasMaster === 'true'" id="realtime-components-layout-home">
        <a-layout id="components-layout-demo">
          <a-layout-sider :trigger="null" collapsible v-show="visible" class="tree-sider lf" ref="leftRef">
            <left-tree ref="leftTree" />
            <div v-if="visible" class='left-icon' @click="visible= false"> 
                <svg-icon style="height:40px;margin-right: 0;" :icon-class="'arrow-left'" />
              </div>
          </a-layout-sider>
          <a-layout class="rf">
            <div v-if="!visible" class='right-icon' @click="visible= true"> 
                <svg-icon style="font-size:40px;margin-right: 0;" :icon-class="'arrow-right'" />
              </div>
            <a-layout-content :style="{ minHeight: '280px' }">
              <Tables :value="panesList" :defaultSelectedKeys="operationConsole.showItem" @changeTable="changeTable" @delAll="delAll" @delOption="delOption" @clickDelIcon="clickDelIcon">
                <template slot="rightContent">
                    <CommondContent ref="commondContent" />
                </template>
              </Tables>
            </a-layout-content>
          </a-layout>
        </a-layout>
      </a-layout>
      <div v-else style="height: calc(100vh - 48px);display: flex; justify-content: center; align-items: center" >
        <HostEmpty :hasMaster="hasMaster" @getHostGroup="getHostGroup" @creatMaster="creatMaster" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";

import _ from "lodash";
import LeftTree from "./leftTree.vue";
import CommondContent from "./components/commondContent.vue";
import EditorOrder from "./components/editorOrder.vue";
import DeleteOrder from "./components/deleteOrder.vue";
import Tables from "@/components/commonTabs";
import HostEmpty from '@/components/empty-host/index.vue'
// import Search from 'ant-design-vue'
export default {
  name: "quickAction",
  // 注册组件
  components: {
    CommondContent,
    Tables,
    HostEmpty,
    LeftTree,
  },
  provide() {
    return {};
  },
  // 实例数据
  data() {
    return {
      treeSpain: false,
      showPage: false,
      visible: true,
      leftRef: null,
      clientStartX: 0,
      treeLayoutWidth: 0,
    };
  },
  computed: {
    ...mapState({
      operationConsole: (state) => state.operationConsole,
      // masterMd: (state) => state.masterMd,
    }),
    ...mapGetters("masterMd", ["hasMaster"]),

    panesList() {
      let list = _.cloneDeep(this.operationConsole.realEditList);
      list.unshift({
        name: "所有主机",
        isLeaf: false,
        key: "groupAll",
        pid: 0,
        index: 0,
        svgIcon: "ope-group",
        title: "所有主机",
        children: [],
        scopedSlots: { title: "host" },
      });
      return list;
    },
  },
  beforeCreate() {},
  created() {
    // this.showPage = this.masterMd.hasMaster
    this.getMaster()
    // this.initShowItem();
    // this.getHostGroup()
  },
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {
    this.changeRealEditList([]);
  },
  destroyed() {},
  watch: {},
  methods: {
    ...mapActions("operationConsole", [
      "setSysType",
      "changeRealEditList",
      "changeShowItem",
    ]),
    ...mapActions("masterMd", ["setHasMaster"]),
    creatMaster(val) {
      this.showPage = true;
    },
    getMaster() {
      this.spinning = true;
      this.$axiosGet(global.API.getMasterInfo).then((res) => {
        this.spinning = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          const arr = res.data.results.filter(item => item.status === 'online');
          if (arr.length > 0) {
            this.setHasMaster(true);
            this.initShowItem();
          } else {
            this.setHasMaster(false);
          }
        }
      });
    },
    getHostGroup() {
      this.treeSpain = true;
      const params = {
        keyword: '',
        page_size: 1000,
      };
      this.$axiosGet(global.API.getHostList, params).then((res) => {
        this.treeSpain = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          if (res.data.results && res.data.results.length > 0) {
            this.showPage = true
          } else {
            this.showPage = false
          }
        }
      });
    },
    initShowItem() {
      this.changeShowItem({
        name: "所有主机",
        isLeaf: false,
        key: "groupAll",
        pid: 0,
        svgIcon: "ope-group",
        title: "所有主机",
        children: [],
        scopedSlots: { title: "host" },
        index: 0,
      });
    },
    confirm() {
      this.$refs.commondContent.confirm();
    },
    changeTable(e) {
      this.$refs.leftTree.changeTable(e);
    },
    clickDelIcon(e) {
      if (e.e) e.e.stopPropagation();
      this.$refs.leftTree.clickDelIcon(e);
    },
    delAll() {
      this.$refs.leftTree.delAll();
    },
    delOption(index, type) {
      this.$refs.leftTree.delOption(index, type);
    },
    moveHandle(x, ref) {
      const computedX = x - this.clientStartX;
      const leftWidth = parseInt(ref.style.width);
      let changeWidth = leftWidth + computedX;
      this.treeLayoutWidth = changeWidth - 200 > 0 ? changeWidth - 200 : 0;
      if (changeWidth < 120) changeWidth = 120;
      if (changeWidth > 1200) changeWidth = 1200;
      ref.style.width = changeWidth + "px";
      this.clientStartX = x;
    },
  },
  filters: {},
};
</script>
  <style lang="less">
.popover-index {
  .more-menu-btn:hover {
    background: #f4f5f7;
    color: #0264c8;
  }
  .ant-popover-inner-content {
    padding: 12px 0;
  }
}
.operation-console {
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
  .dot {
    position: relative;
    top: -2px;
    margin-right: 4px;
    width: 6px;
    height: 6px;
    display: inline-block;
    border-radius: 50%;
  }
  .sysDevStyle {
    height: calc(100% - 35px) !important;
    overflow-y: scroll;
  }
  .ant-tree-node-selected {
    color: rgba(0, 0, 0, 0.65) !important;
    background: none !important;
  }
  .ant-tree li .ant-tree-node-content-wrapper:hover {
    background: none;
  }
  .hide-point {
    display: none;
  }
  .ant-tree-child-tree-open,
  .ant-tree-node-content-wrapper {
    li:not(.ant-tree-treenode-switcher-open):hover {
      background: #e6e8ed;
    }
    .hide-point {
      display: block;
    }
  }
  .ant-popover-inner-content {
    padding: 6px 0px !important;

    .ant-btn:hover,
    .ant-btn:focus {
      background-color: #f0faff;
    }
  }
  #realtime-components-layout-home {
    height: calc(100vh - 48px);
    .ant-menu-item-selected {
      background: #2872e0;
      color: #fff;
    }
    .title-content {
      position: absolute;
      top: 8px;
      left: 20px;
      font-size: 16px;
      color: #1d202d;
      font-weight: 600;
    }
  }

  .ant-menu-item a {
    display: inline-block;
  }
  .tree-sider {
    min-width: 292px !important;
    .tree-sider-title {
      display: flex;
      padding: 8px 12px 0 12px;
    }
    .sider-header-button {
      padding: 16px 8px 5px 0;
      .ant-input-search {
        margin-left: 16px;
        margin-right: 16px;
        .ant-input-affix-wrapper .ant-input-suffix {
          right: 6px;
        }
      }
      .ant-input-affix-wrapper {
        margin-left: 12px;
        margin-right: 4px;
        margin-left: 24px;
      }
      .ant-input {
        border-radius: 2px;
        margin: 0;
        height: 30px;
        line-height: 30px;
        padding-left: 8px;
      }
      .sider-header-btn {
        box-shadow: none;
      }
    }
  }
  .lf {
    position: relative;
    top: 0;
    width: 292px;
    height: 100%;
    background: #fff;
    border-right: 1px solid rgba(209, 210, 215, 1);
    .left-icon {
        position: absolute;
        right: -2px;
        top: 43%;
        cursor: pointer;
        z-index: 100;
      }
    .ant-tree li ul li {
      padding: 5px 0 5px 16px;
      border-bottom: 1px solid #f6f7fb;
    }
    .ant-tree li ul:not(.ant-tree-child-tree-open) {
      padding: 5px 0 5px 16px;
    }
    .ant-tree-child-tree-open {
      padding: 0;
    }
    .left-tree-container {
      max-height: calc(100% - 30px);
      overflow-y: auto;
      margin-left: 14px;
      margin-right: 12px;
    }
  }
  .rf {
    flex: 1;
    overflow: auto;
    height: 100%;
    margin-top: 0;
    background: #fff;
    // margin-left: 16px;
    .right-icon {
      position: absolute;
      left: -15px;
      z-index: 100;
      top: 43%;
      cursor: pointer;
    }
  }
}
</style>
  