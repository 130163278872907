<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-09-05 14:51:38
 * @LastEditTime: 2022-09-09 10:08:26
 * @FilePath: \awx-ui\src\pages\hostManage\components\uploadFile.vue
-->
<template>
  <div class="upload-file">
    <div class="flex-upload">
      <div style="margin-right: 20px">请选择文件:</div>
      <a-upload name="file" :multiple="false" :fileList="fileList" @change="handleChange" :accept="fileType" :remove="handleRemove" :beforeUpload="beforeUpload">
        <a-button style="width: 180px">
          <a-icon type="upload" />导入
        </a-button>
      </a-upload>
      <div class="mgl10 download-template" @click="downloadFile()">下载模板</div>
    </div>
    <div v-if="resUrl">导入结果：<span>{{resMsg}}</span> <span class="failed-url mgl10 download-template" @click="downloadFile(resUrl)">查看失败原因</span></div>
    <div class="footer">
      <a-button class="mgr10" type="primary" @click="hostsImport">导入</a-button>
      <a-button @click="handleCancel">取消</a-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    callBack: Function
  },
  data() {
    return {
      fileList: [],
      headers: {
        authorization: "authorization-text",
      },
      fileType: ".xls, .xlsx, .csv",
      resUrl: '',
      resMsg: ''
    };
  },
  methods: {
    downloadFile(resUrl) {
      let apiAjax = resUrl ? '/awx' + resUrl : global.API.hostsImportTemplate
      this.$axiosGet(apiAjax, {}).then((res) => {
        let blob = new Blob([res.data]);//response.data为后端传的流文件
        let downloadFilename = !resUrl ?  "主机组" + "模板.xls" : "导入结果.xls";//设置导出的文件名
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //兼容ie浏览器
          window.navigator.msSaveOrOpenBlob(blob, downloadFilename)
        }else {
        //谷歌,火狐等浏览器
          let url = window.URL.createObjectURL(blob);
          let downloadElement = document.createElement("a");
          downloadElement.style.display = "none";
          downloadElement.href = url;
          downloadElement.download = downloadFilename;
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(url);
          this.$nextTick(() => {
            if (flag) {
              this.$message.success("下载成功！");
            }
          });
        }
      });
      return;
    },
    handleCancel() {
      this.$destroyAll();
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      // if (file.size > 10 * 1024 * 1000) {
      //   this.$message.error("文件上传最大仅支持10M");
      //   return;
      // }
      this.fileList = [file];
      return false;
    },
    handleChange(e) {
      // if(e.file.size > (10*1024*1000)){
      //   return
      // }
      this.file = e.file;
    },
    hostsImport() {
      let formdata = new FormData();
      formdata.append("file", this.file);
      this.$axiosFormData(global.API.hostsImport, formdata).then((res) => {
        console.log(res);
        if ([200, 201, 202, 204].includes(res.status)) {
          if (res.data.code === 200) {
            this.resMsg = res.data.msg
            if (Number(res.data.data.fail) > 0) this.resUrl = res.data.data.url
            this.callBack()
          } else {
            this.$message.error(res.data.msg)
            this.resUrl = res.data.data.url
          }
        } 
      });
    },
  },
};
</script>
<style lang="less" scoped>
.upload-file {
  padding: 16px 32px 20px;
  .flex-upload {
    display: flex;
    justify-content: flex-start;
  }
  .download-template {
    color: @primary-color;
    cursor: pointer;
  }
  .footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>