var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-top":"10px"}},[_c('a-form',{staticClass:"p0-32-10-32 form-content",attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"命令名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          { rules: [{ required: true, message: '命令名称不能为空!' }] },
        ]),expression:"[\n          'name',\n          { rules: [{ required: true, message: '命令名称不能为空!' }] },\n        ]"}],attrs:{"placeholder":"请输入命令名称"}})],1),_c('a-form-item',{attrs:{"label":"描述"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'desc',
          { rules: [{ required: false, message: '描述不能为空!' }] },
        ]),expression:"[\n          'desc',\n          { rules: [{ required: false, message: '描述不能为空!' }] },\n        ]"}],attrs:{"type":"textarea","placeholder":"请输入描述"}})],1)],1),_c('div',{staticClass:"ant-modal-confirm-btns-new"},[_c('a-button',{staticStyle:{"margin-right":"10px"},attrs:{"type":"primary","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v("确认")]),_c('a-button',{on:{"click":function($event){$event.stopPropagation();return _vm.formCancel.apply(null, arguments)}}},[_vm._v("取消")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }