<!--
 * @Author: mjzhu
 * @Date: 2022-06-08 11:38:30
 * @LastEditTime: 2023-02-27 20:06:40
 * @FilePath: \awx-ui\src\pages\operationConsole\components\bottomOpe\addCmd.vue
-->
<template>
  <div style="padding: 10px 10px 10px 0">
    <a-form :label-col="labelCol" :wrapper-col="wrapperCol" :form="form" class="p0-32-10-32 form-content-host">
      <a-form-item label="命令类型">
        <a-select placeholder="请选择命令类型" v-decorator="[
              'lang',
              { rules: [{ required: true, message: '命令类型不能为空!' }] },
              ]">
          <a-select-option v-for="item in tabsItem" :key="item.key" :value="item.key">{{item.label}}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="名称">
        <a-input v-decorator="['name',{ rules: [{ required: true, message: '名称不能为空!' }] }]" @change="onChange" />
      </a-form-item>
      <a-form-item label="详细内容">
        <editor id="editor" ref="formEditor" :editorHeight="'200px'" :propsCode="logData" :readOnly="false" theme="vs-dark" language="language" @getEditorValue="getEditorValue" />
      </a-form-item>
    </a-form>
    <div class="ant-modal-confirm-btns-new" style="margin: 0">
      <a-button style="margin-right: 10px" type="primary" @click.stop="handleSubmit" :loading="loading">确认</a-button>
      <a-button @click.stop="formCancel">取消</a-button>
    </div>
  </div>
</template>
  <script>
import editor from "@/components/editor";
import moment from "moment";

export default {
  components: {
    editor,
  },
  props: {
    ids: {
      type: Array,
      default: function () {
        return [];
      },
    },
    currentTab: String,
    callBack: Function,
  },
  data() {
    const self = this;
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
      },
      currentTabsKey: "bash",
      tabsItem: [
        {
          label: "Bash",
          key: "bash",
        },
        {
          label: "Shell",
          key: "sh",
        },
        {
          label: "Python2",
          key: "python2",
        },
        {
          label: "Python3",
          key: "python3",
        },
        {
          label: "Ruby",
          key: "ruby",
        },
        {
          label: "Perl",
          key: "perl",
        },
        {
          label: "PowerShell",
          key: "powershell",
        },
      ],
      code: "",
      logData: "",
      form: this.$form.createForm(this, {
        onValuesChange: function (props, fileds) {},
      }),
      showAuthFlag: false,
      loading: false,
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 19, offset: 5 },
        },
      },
      labelsList: [],
      nameList: [],
      editAble: false,
    };
  },
  watch: {},
  methods: {
    onChange(val) {
      this.editAble = true;
    },
    getEditorValue(value) {
      this.code = value;
      if (this.editAble) return false;
      let name = "";
      if (value.length > 14) {
        name = value.substring(0, 14);
      } else {
        name = value;
      }
      this.form.getFieldsValue(["name"]);
      this.form.setFieldsValue({
        name: name,
      });
      // }
    },
    formCancel() {
      this.$destroyAll();
    },
    handleSubmit(e) {
      const _this = this;
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log(values);
        if (!err) {
          if (!this.code) {
            this.$message.error("请输入详细内容");
            return false;
          }
          this.loading = true;
          const params = {
              name: values.name,
              lang: values.lang,
              code: this.code || this.logData,
              timeout: "",
            };
          if (this.currentTab === "cmd") {
            const ajaxApi = global.API.getOrderList;
            this.$axiosPatch(ajaxApi, params).then((res) => {
              this.loading = false;
              if ([200, 201, 204, 202].includes(res.status)) {
                _this.callBack()
                _this.$destroyAll()
                _this.$message.success("命令保存成功");
              }
            });
          } else if (this.currentTab === "script") {
            params.filename =  values.name
            params.content= this.code
            this.loading = false;
            const ajaxApi =  global.API.uploadFile + '/scripts'
            this.$axiosProxyPut(ajaxApi, params).then((res) => {
              this.loading = false;
              if ([200, 201, 204, 202].includes(res.status)) {
                _this.callBack()
                _this.$destroyAll()
                _this.$message.success("脚本保存成功");
              }
            });
          }
        }
      });
    },
    init() {
      this.form.getFieldsValue(["name", "lang"]);
      this.form.setFieldsValue({
        lang: "bash",
        name: "主机命令_" + moment(new Date()).format("YYYYMMDDHHmmss"),
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
    <style lang="less" scoped>
.form-content-host {
  max-height: 580px;
  //   overflow-y: auto;
}
/deep/.ant-modal-footer {
  display: none;
}
</style>
    