<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-02-06 14:55:25
 * @LastEditTime: 2024-01-27 21:29:04
 * @FilePath: \awx-ui\src\pages\operationConsole\leftTree.vue
-->
<template>
  <div class="left-tree" :style="{ height: 'calc(100% - 0px)' }">
    <div class="sider-header-button">
      <a-select style="width: calc(100% - 60px); margin-left: 24px;" v-model="cluster_id" @change="convertTreeData">
        <a-select-option v-for="(item, index) in masterOptions" :disabled="item.status !== 'online'" :key="item.id" :value="item.id">
          <span class="flex-center-container">
             <svg-icon class="mgr8" :icon-class="item.status === 'online' ? 'task-successful' : 'task-init'" :style="{color: item.status === 'online' ? '#5CB863' : '#ED4F50'}"></svg-icon>
             <span>{{ item.name }}</span>
          </span>
        </a-select-option>
      </a-select>
      <div style="min-width: 45px;">
        <a-tooltip
          placement="bottom"
          :title="item.title"
          arrow-point-at-center
          v-for="(item) in treeTopList" :key="item.key"
        >
          <a-button
            :icon="item.icon"
            class="sider-header-btn"
            style="font-size:14px;border-width: 0px;background:rgba(0,0,0,0);width: 20px;"
            @click="(event) => clickPopCommon(item)"
          />
        </a-tooltip>
      </div>
    </div>
    <a-spin :spinning="treeSpain" class="left-tree-container">
      <div :class="'sysDevStyle'" style="overflow-y: auto; padding: 0 10px">
        <a-tree :treeData="treeData" class="a-tree-local-layout" @expand="onExpand" :expandedKeys="expandedKeys" :autoExpandParent="autoExpandParent" :selectedKeys="selectedKeys" :load-data="onLoadData" defaultExpandAll @select="onSelect">
          <template slot="host" slot-scope="item">
            <div :class="['local-tree-custom', item.isLeaf && 'pdl15']">
              <template>
                <span style="margin: 0 4px 1px">
                  <svg-icon :icon-class="item.isLeaf ? 'ope-host': 'ope-group'" style="font-size: 16px"></svg-icon>
                </span>
              </template>
              <span class="local-tree-content" :title="item.title">
                <span v-if="item.title.indexOf(searchValue) == -1">{{ item.title }}</span>
                <span v-else :title="item.title">
                  {{
                  item.title.substr(
                  0,
                  item.title.indexOf(searchValue)
                  )
                  }}
                  <span style="color: #f50">{{ searchValue }}</span>
                  {{
                  item.title.substr(
                  item.title.indexOf(searchValue) +
                  searchValue.length
                  )
                  }}
                </span>
              </span>
              <!-- <span class="tree-more hide-point" style="width: 10px; text-align: right">
                <div @click.stop>
                  <a-popover placement="rightTop" class="popover-index" overlayClassName="popover-index" :content="() => getMoreMenu(item)">
                    <a-icon type="more" @click="showPopover(item)" />
                  </a-popover>
                </div>
              </span> -->
            </div>
          </template>
          <template slot="target" slot-scope="item">
            <div :class="['local-tree-custom', item.isLeaf && 'pdl15']">
              <template>
                <span style="margin: 0 4px">
                  <svg-icon :icon-class="item.isLeaf ? 'ope-target-host' : 'ope-target-group'" style="color: #919ab2;font-size: 16px"></svg-icon>
                </span>
              </template>
              <span class="local-tree-content" :title="item.title">{{ item.name }}</span>
            </div>
          </template>
        </a-tree>
      </div>
    </a-spin>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      searchValue: "",
      autoExpandParent: true,
      selectedKeys: [],
      expandedKeys: ["主机分组"],
      treefilterData: [],
      cluster_id: undefined,
      treeData: [],
      treeSpain: false,
      treeTopList:[
        { title: "定位", key: "environment", path: "/", icon: "environment" },
        { title: "刷新", key: "sync", path: "/", icon: "sync" },
      ],
    };
  },
  mounted() {
    this.initClusterid()
  },
  computed: {
    ...mapState({
      operationConsole: (state) => state.operationConsole,
    }),
    masterOptions () {
      const options = localStorage.getItem('masterOptions')
      const masterOptions = (options && options.length) ? JSON.parse(options) : []
      return masterOptions
    }
  },
  methods: {
    ...mapActions("operationConsole", [
      "setSysType",
      "changeRealEditList",
      "changeShowItem",
      "setClusterId"
    ]),
    initClusterid () {
      if (!this.masterOptions) this.cluster_id = undefined
      const onLlineCluster = this.masterOptions.filter(item => item.status === 'online')
      if (onLlineCluster.length > 0) {
        const DefaultMaster = onLlineCluster.find(item => (item.is_default))
        this.cluster_id = DefaultMaster?.id || onLlineCluster[0]?.id || undefined
      }
      this.convertTreeData();
    },
    onExpand(expandedKeys, { node }) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    changeSearchValue() {
      // this.getHostGroups();
      this.convertTreeData();
    },
    // 动态获取文件树
    onLoadData(treeNode) {
      return new Promise((resolve) => {
        if (
          treeNode.dataRef.name === "主机分组" ||
          treeNode.dataRef.name === "目标分组"
        )
          return resolve();
        if (treeNode.dataRef.children && treeNode.dataRef.children.length) {
          resolve();
          return;
        }
        let apiAjax =
          treeNode.dataRef.scopedSlots.title === "host"
            ? global.API.getHostGroups + `/${treeNode.dataRef.id}/hosts`
            : global.API.getTargets + `/${treeNode.dataRef.id}/minions`;

        this.$axiosGet(apiAjax, {cluster_id: this.cluster_id}).then((res) => {
          const result =
            treeNode.dataRef.scopedSlots.title === "host"
              ? res.data.results
              : res.data;
          if ([200, 201, 204].includes(res.status)) {
            if (result && result.length > 0) {
              treeNode.dataRef.children = result.map((val) => {
                return {
                  title:
                    treeNode.dataRef.scopedSlots.title === "host"
                      ? val.name
                      : val.nodename,
                  name:
                    treeNode.dataRef.scopedSlots.title === "host"
                      ? val.name
                      : val.nodename,
                  svgIcon: treeNode.dataRef.scopedSlots.title === "host"
                      ? 'ope-host'
                      : 'ope-target-host',
                  key: val.id,
                  isLeaf: true,
                  scopedSlots: { title: treeNode.dataRef.scopedSlots.title },
                  ...val,
                };
              });
              this.treeData = [...this.treeData];
            }
            resolve();
          } else {
            resolve();
          }
        });
      });
    },
    disTreeData(data, _expandedKeys) {
      if (data && data.length > 0) {
        data.forEach((item) => {
          if (_expandedKeys && _expandedKeys.indexOf(item.key) < 0) {
            item["style"] = { display: "none" };
          } else {
            item["style"] = { display: "block" };
          }
          if (item.children && item.children.length != 0) {
            this.disTreeData(item.children, _expandedKeys);
          }
        });
      }
    },
    convertTreeData() {
      this.setClusterId(this.cluster_id)
      this.treeData = [
        {
          name: "主机分组",
          isLeaf: false,
          key: "主机分组",
          title: "主机分组",
          children: [],
        }
      ];
      this.labelsList = [];
      if (!this.cluster_id) return false
      this.treeSpain = true;
      const params = {
        keyword: this.searchValue,
        page_size: 1000,
        cluster_id: this.cluster_id
      };
      this.$axiosGet(global.API.getHostGroups, params).then((res) => {
        this.treeSpain = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          if (res.data.results && res.data.results.length > 0) {
            res.data.results.map((item) => {
              this.treeData[0].children.push({
                name: item.name,
                isLeaf: false,
                key: "group" + item.id,
                pid: 0,
                svgIcon: 'ope-group',
                title: item.name,
                children: [],
                ...item,
                scopedSlots: { title: "host" },
              });
              this.labelsList.push(item.name);
            });
          }
        }
      });
      this.expandedKeys = ["主机分组"];
    },
    showPopover(item) {
      this.popoverVisible = true;
    },
    showPopDetail(keys, node) {
      /**
       * 操作集
       */
      this.popoverVisible = false;
      const self = this;
      let width = 560;
      let title = "编辑命令";
      let content =
        keys === "edit" || keys === "build" ? (
          <EditorOrder detail={node} callBack={() => self.convertTreeData()} />
        ) : (
          <DeleteOrder
            sysTypeTxt="命令"
            detail={node}
            type="group"
            refreshList={() => self.convertTreeData()}
          />
        );
      this.$confirm({
        width: width,
        title:
          keys === "edit" || keys === "build"
            ? title
            : () => {
                return (
                  <div>
                    <a-icon
                      type="question-circle"
                      style="color:#2F7FD1 !important;margin-right:10px"
                    />
                    提示
                  </div>
                );
              },
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    addMenu() {
      const self = this;
      let width = 560;
      let title = "添加主机组";
      let content = <AddGroup callBack={() => self.getHostGroups()} />;
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    destroyAll() {
      this.$destroyAll();
    },
    getMoreMenu(props) {
      const { isLeaf, expanded, type, editStatus, name, dataRef, pNodeId } =
        props;
      let arr = [];
      if (name === "全部主机组") {
        arr = [{ name: "新建主机组", key: "build" }];
      }
      if (name !== "全部主机组") {
        arr = [
          { name: "编辑", key: "edit" },
          { name: "删除", key: "delete" },
        ];
      }
      return arr.map((item, index) => {
        return (
          <div key={index}>
            <a-button
              class="more-menu-btn"
              style="border-width:0px;min-width:100px;"
              onClick={() => this.showPopDetail(item.key, props)}
            >
              {item.name}
            </a-button>
          </div>
        );
      });
    },
    onSelect(keys, { node }) {
      if (['主机分组', '目标分组'].includes(node.dataRef.name)) return false
      if (node.isLeaf) return this.$message.warning('页面待开发')
      // 点击打开的父节点的时候将父节点选中收起来
      if (node.expanded && !node.isLeaf) {
        this.expandedKeys = this.expandedKeys.filter(
          (item) => item !== node.eventKey
        );
      }
      if (!keys.length) return false;
      this.selectedKeys = keys;
      this.pushTable(node.dataRef);
    },
    clickTab(key) {
      this.currentTabsKey = key;
    },
    clickPopCommon(item) {
      if (item.key == "search") {
        this.changeSearchValue();
      }
      if (item.key == "sync") {
        this.changeSearchValue();
      }
      if(item.key =='environment'){
        this.selectedKeys = [this.operationConsole.showItem.key]
        if (this.operationConsole.showItem.svgIcon === 'ope-group')  {
          this.expandedKeys = ['主机分组']
        } else{
          this.expandedKeys = ['目标分组']
        }
      }
    },
    pushTable(val) {
      let bool = true;
      let selectItemKey =
        val.index || this.operationConsole.realEditList.length + 1;
      this.operationConsole.realEditList.map((item, index) => {
        if (
          (val.path && val.path == item.path) ||
          (val.nodeId && val.nodeId == item.nodeId) ||
          (val.id && val.id == item.id)
        ) {
          bool = false;
          selectItemKey = index;
        }
      });
      if (bool) {
          //作业
          if (!val.pageData) {
            val["pageData"] = {};
          }
          let json = {
            index: selectItemKey,
            jobType: "task",
            history: {
              undoStack: [],
              redoStack: [],
            },
            ...val,
          };
          if (val.taskSource) {
            //节点
            json.jobType = "script";
          }
          const openJosn = _.cloneDeep(json);
          const list = _.cloneDeep(this.operationConsole.realEditList);
          //记录当前打开页面
          this.operationConsole.showItem = openJosn;
          this.changeShowItem(openJosn);
          list.push(openJosn);
          this.changeRealEditList(list);
      } else {
        this.operationConsole.showItem = {
          index: selectItemKey,
          ...this.operationConsole.realEditList[selectItemKey],
        };
        this.changeShowItem(this.operationConsole.showItem);
      }
    },
    removeTable(index) {
      // 因为前面多了一个所有主机这个独立的不可关闭的tab 所以index - 1
      index = index - 1
      const realEditList = _.cloneDeep(this.operationConsole.realEditList);
      let bool = false;
      // 每一次关闭之后 如果后面有标签打开后面一个 如果已经是最后一个  打开前面一个
      if (realEditList.length > 0) {
        // 这个是直接关闭当前这个tab
        this.operationConsole.realEditList.splice(index, 1);
        this.changeRealEditList(this.operationConsole.realEditList);
        // 这个是关闭的标签刚好是打开的这个tab
        if (index + 1 === this.operationConsole.showItem.index) {
          // 如果已经是最后一个  打开前面一个
          if (index === realEditList.length - 1) {
            if (index === 0) {
              this.operationConsole.showItem = {
                name: '所有主机',
                isLeaf: false,
                key: "groupAll",
                pid: 0,
                index: 0,
                svgIcon: 'ope-group',
                title: '所有主机',
                children: [],
                scopedSlots: { title: "host" },
              }
            } else {
              this.operationConsole.showItem = {
                ...this.operationConsole.realEditList[index - 1],
                index: index,
              };
            }
          } else {
            // 如果不是是最后一个  打开当前的后面的一个
            this.operationConsole.showItem = {
              ...this.operationConsole.realEditList[index],
              index: index + 1,
            };
          }
        } else if (index + 1 < this.operationConsole.showItem.index) {
          this.operationConsole.showItem.index--;
        }
        bool = true;
      }
      // if (this.operationConsole.realEditList.length === 0)
      //   this.operationConsole.showItem = {};
      this.selectedKeys = [this.operationConsole.showItem.key];
      this.changeShowItem(this.operationConsole.showItem);
      return bool;
    },
    // 右鍵菜单操作
    delOption(e) {
      // 因为前面多了一个所有主机这个独立的不可关闭的tab
      e.index = e.index - 1
      if (e.type === "other") {
        this.operationConsole.realEditList = [this.operationConsole.realEditList[e.index]];
        this.operationConsole.showItem = {
          ...this.operationConsole.realEditList[0],
          index: 1,
        };
        this.selectedKeys = [this.operationConsole.showItem.key];
        this.changeShowItem(this.operationConsole.showItem);
      }
      if (e.type === "left") {
        // 操作的是最左边这个tab 关闭左边不相应
        if (e.index === -1) return false
        this.operationConsole.realEditList.splice(0, e.index);
        if (e.index + 1 < this.operationConsole.showItem.index) {
          this.operationConsole.showItem = {
            ...this.operationConsole.realEditList[
              this.operationConsole.showItem.index - e.index - 1
            ],
            index: this.operationConsole.showItem.index - e.index,
          };
        } else {
          this.operationConsole.showItem = {
            ...this.operationConsole.realEditList[0],
            index: 1,
          };
        }
        this.selectedKeys = [this.operationConsole.showItem.key];
        this.changeShowItem(this.operationConsole.showItem);
      }
      if (e.type === "right") {
        // 操作的是最左边这个tab 关闭右边那么只保留他自己
        if (e.index === -1) {
          this.delAll()
          return false
        }
        this.operationConsole.realEditList.splice(
          e.index + 1,
          this.operationConsole.realEditList.length
        );
        if (e.index + 1 > this.operationConsole.showItem.index) {
          // 
        } else {
          this.operationConsole.showItem = {
            ...this.operationConsole.realEditList[
              this.operationConsole.realEditList.length - 1
            ],
            index: this.operationConsole.realEditList.length,
          };
          this.selectedKeys = [this.operationConsole.showItem.key];
          this.changeShowItem(this.operationConsole.showItem);
        }
      }
      this.changeRealEditList(this.operationConsole.realEditList);
    },
    delAll() {
      this.changeRealEditList([]);
      this.changeShowItem({
        name: '所有主机',
        isLeaf: false,
        key: "groupAll",
        pid: 0,
        index: 0,
        svgIcon: 'ope-group',
        title: '所有主机',
        children: [],
        scopedSlots: { title: "host" },
      });
      this.selectedKeys = [];
    },
    changeTable(e) {
      if (e.index == this.operationConsole.showItem.index) {
        return;
      }
      let item = e.item || {};
      this.operationConsole.showItem = {
        ...e.item,
        index: e.index,
      };
      this.changeShowItem(this.operationConsole.showItem);
      // 左侧树选中
      if (item && item.key && item.key !== "workList") {
        this.selectedKeys = [].concat(item.key + "");
        const arr = item.pKeys || [];
        this.expandedKeys = this.expandedKeys.concat(
          arr.map((v) => {
            return Number(v);
          }) || []
        );
      }
      if (item.key === "workList") this.selectedKeys = [];
    },
    clickDelIcon(e) {
      let self = this;
      if (e.item.edit) {
        this.$confirm({
          width: 450,
          title: () => {
            return (
              <div style="font-size: 22px;">
                <a-icon
                  type="question-circle"
                  style="color:#2872E0 !important;margin-right:10px"
                />
                提示
              </div>
            );
          },
          content: (
            <div style="margin-top:20px">
              <div style="padding:0 65px;font-size: 16px;color: #555555;">
                作业未保存，是否确认关闭？
              </div>
              <div style="margin-top:20px;text-align:right;padding:0 30px 30px 30px">
                <a-button
                  style="margin-right:10px;"
                  type="primary"
                  onClick={() => {
                    e.item.edit = false;
                    this.clickDelIcon(e);
                  }}
                >
                  确定
                </a-button>
                <a-button
                  style="margin-right:10px;"
                  onClick={() => this.$destroyAll()}
                >
                  取消
                </a-button>
              </div>
            </div>
          ),
          icon: () => {
            return <div />;
          },
          closable: true,
        });
      } else {
        // 删除当前编辑页面
        let bool = this.removeTable(e.index);
        // 重新设置默认打开页面 ==
        if (bool) {
          this.$destroyAll();
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
// 右边的气泡
.popover-index {
  .more-menu-btn:hover {
    background: #f4f5f7;
    color: #0264c8;
  }
  .ant-popover-inner-content {
    padding: 12px 0;
  }
  /deep/ .ant-popover-arrow {
    top: 20px !important;
    left: 20px !important;
  }
  .ant-popover-inner-content .ant-btn:hover,
  .ant-popover-inner-content .ant-btn:focus {
    background-color: #f4f5f7;
  }
  .ant-menu-vertical .ant-menu-item:not(:last-child),
  .ant-menu-vertical-left .ant-menu-item:not(:last-child),
  .ant-menu-vertical-right .ant-menu-item:not(:last-child),
  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 4px;
    margin-top: 1px;
  }
  .ant-menu-inline-collapsed {
    width: 50px;
  }
  .ant-menu-item-selected {
    background: #2872e0 !important;
    color: #ffffff !important;
  }
}
.left-tree {
  // 搜索框
  .sider-header-button {
    display: flex;
    padding: 4px 8px 5px 0;
    .ant-input-search {
      margin-left: 16px;
      margin-right: 16px;
      .ant-input-affix-wrapper .ant-input-suffix {
        right: 6px;
      }
    }
    .ant-input-affix-wrapper {
      margin-left: 12px;
      margin-right: 4px;
      margin-left: 24px;
    }
    .ant-input {
      border-radius: 2px;
      margin: 0;
      // background-color: #F2F4F7;
      // border-width:0px
      height: 30px;
      line-height: 30px;
      padding-left: 8px;
    }
    .sider-header-btn {
      box-shadow: none;
    }
  }
  .left-tree-container {
    max-height: calc(100% - 30px);
    overflow-y: auto;
    margin-left: 14px;
    margin-right: 12px;
    .sysDevStyle {
      height: calc(100% - 35px) !important;
      overflow-y: scroll;
      .a-tree-local-layout {
        // padding: 0 14px 0 0px;

        .ant-tree li ul li {
          padding: 5px 0 5px 16px;
          border-bottom: 1px solid #f6f7fb;
        }
        .ant-tree li ul:not(.ant-tree-child-tree-open) {
          padding: 5px 0 5px 16px;
        }
        .ant-tree-child-tree-open {
          padding: 0;
        }
        .ant-tree-treenode-selected:not(.ant-tree-treenode-switcher-open) {
          background: #cce1fe;
          border-radius: 4px;
          .local-tree-content {
            // font-size: 14px;
            color: #0264c8 !important;
            letter-spacing: 0;
            font-weight: 400;
          }
          .popover-index {
            color: #0181ff;
          }
        }
        .ant-tree-treenode-switcher-close {
          // background-color: #fff !important;
        }
        .ant-tree-node-content-wrapper {
          width: calc(100% -24px) !important;
          padding: 0 10px 0 0 !important;
          background: rgba(0, 0, 0, 0) !important;
          opacity: 1 !important;
          border-width: 0px;
          line-height: 24px;
        }
        .local-tree-custom {
          display: flex;
          align-items: center;
          .local-tree-content {
            padding: 0 5px;
            color: #000000a6;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .tree-more {
            color: #f2f4f7;
          }
        }
        .pdl15 {
          position: relative;
          left: -15px;
          .local-tree-content {
            width: calc(100%);
          }
          .tree-more {
            position: relative;
            right: -15px;
          }
        }
        .local-tree-custom:hover {
          .tree-more {
            color: #2281e1;
          }
        }
      }
    }
  }
}
</style>