<!--
 * @Author: mjzhu
 * @Date: 2022-06-09 10:11:22
 * @LastEditTime: 2024-01-27 11:27:02
 * @FilePath: \awx-ui\src\pages\operationConsole\components\commondContent.vue
-->

<template>
  <div class="action-content">
    <div class="body-left">
      <div class="table">
        <div class="search-card">
          <a-row type="flex" align="middle">
            <a-col :span="24">
              <a-input style="width: 300px" v-model="params.keyword" @pressEnter="onSearch" placeholder="输入关键字">
                <span style="cursor: pointer;" slot="suffix" @click="onSearch" >
                  <svg-icon  icon-class="realtime-search" />
                </span>
              </a-input>
              <a-auto-complete v-model="dest"
              class="mgl12"
               style="width: 300px"
               :data-source="labelList" placeholder="请选择或输入目标路径" @change="handleChange" @select="handleSelect" />
               <span class="mgl12">文件重名</span>
               <a-switch size="large" class="mgl12 switch-action" v-model="replace" style="font-size: 14px" checked-children="覆盖" un-checked-children="覆盖" />
               <span class="mgl12">路径不存在</span>
               <a-switch size="large" class="mgl12 switch-action mgr12" v-model="makedirs" style="font-size: 14px" checked-children="创建" un-checked-children="创建" />
                执行方式：
                <a-radio-group v-model="execute">
                  <a-radio value="local">Agent</a-radio>
                  <a-radio value="ssh">SSH</a-radio>
                </a-radio-group>
            </a-col>
          </a-row>
        </div>
        <div class="table-card">
          <div class="table-info">
            <a-table v-if="!isHost" @change="markChange" :row-selection="{
                selectedRowKeys: selectedGroupRowKeys,
                onChange: onSelectGroup,
              }" :columns="markColumns" :loading="markLoading" :dataSource="targetData" rowKey="index" :pagination="false"></a-table>
            <a-table v-else @change="groupChange"  :row-selection="{
                selectedRowKeys: selectedGroupRowKeys,
                onChange: onSelectGroup,
              }" :columns="groupColumns" :loading="groupLoading" :dataSource="tableData" rowKey="id" :pagination="false"></a-table>
          </div>
        </div>
      </div>
    </div>
    <BottomOpe ref="bottomOptRef" :selectedGroupRows="selectedGroupRows" @confirm="confirm" />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

import BottomOpe from './bottomOpe/index..vue'
import UploadFile from "./uploadFile.vue";
import BatchLabel from "./batchLabelModal.vue";
import moment from "moment";
import { formatBytes } from "@/utils/util";

export default {
  name: "HOSTLIST",
  props: {
  },
  watch: {
    selectedGroupRowKeys: {
      handler(value) {
        if (value.length > 0) {
          this.unableClear = false;
        } else {
          this.unableClear = true;
        }
      },
      immediate: true,
    },
    selectedMarkRowKeys: {
      handler(value) {
        if (value.length > 0) {
          this.unableClear = false;
        } else {
          this.unableClear = true;
        }
      },
      immediate: true,
    },
  },
  components: {
    BottomOpe
  },
  provide() {
    return {
      fileSync: this.syncFile
    }
  },
  data() {
    return {
      dest: undefined,
      runLoading: false,
      visible: false,
      labelList: [],
      replace: true,
      makedirs: true,
      commondType: '命令',
      orderDetail: {
        timeout: 10,
        name: ''
      },
      scriptParams: {},
      // timeout: "",
      name: undefined,
      category: undefined,
      group: undefined,
      mark: undefined,
      keyword: "",
      execute: "local",
      isAll: false,
      chooseType: [
        {
          label: "主机分组",
          value: "1",
        },
        {
          label: "目标分组",
          value: "2",
        },
      ],
      selectList: [],
      groupList: [],
      markList: [],
      labelCol: {
        xs: { span: 10 },
      },
      wrapperCol: {
        xs: { span: 5 },
      },
      form: this.$form.createForm(this),
      params: {},
      showAdd: false,
      tableData: [],
      targetData: [],
      groupLoading: false,
      markLoading: false,
      selectedGroupRowKeys: [],
      selectedMarkRowKeys: [],
      selectedGroupRows: [],
      selectedMarkRows: [],
      groupColumns: [
        { title: "主机名称", key: "name", dataIndex: "name" },
        {
          title: "主机IP",
          key: "ip",
          dataIndex: "ip",
        },
        {
          title: "同步目录",
          dataIndex: "dest",
          key: "dest",
          ellipsis: true,
          customRender: (text, row, index) => {
            return <span>{row.editable ?   <a-input
            style="height: 28px"
            value={text}
            onBlur={(e) =>
              this.changeTableDest(e.target.value, row.id, "dest")
            }
              /> : <div>
                {text}
                <span class="mgl12" onClick={() => this.edit(row.id)}>
                  <a-icon type="edit" />
                </span>
              </div> 
            }</span>;
          },
        },
        {
          title: "主机组",
          key: "group_name",
          dataIndex: "group_name",
          customRender: (text, row, index) => {
            return <span>{text}</span>;
          },
        },
        {
          title: "主机标签",
          key: "labels",
          dataIndex: "labels",
          customRender: (text, row, index) => {
            return <span>{text.join(",")}</span>;
          },
        },
        {
          title: "端口",
          key: "port",
          dataIndex: "port",
        },
        {
          title: "操作系统",
          key: "osType",
          dataIndex: "osType",
          customRender: (text, row, index) => {
            return <span>
              {text === "Linux" ? <svg-icon class="mgr8" style={{'font-size': '16px'}} icon-class="linux"></svg-icon> : <svg-icon class="mgr8" style={{'font-size': '16px'}} icon-class="windows"></svg-icon>}
              {text}
            </span>
          },
        },
      ],
      markColumns: [
        /* {
          title: "minion id",
          key: "nodename",
          dataIndex: "nodename",
        }, */
        {
          title: "主机IP",
          key: "ipv4",
          dataIndex: "ipv4",
          ellipsis: true,
          customRender: (text, row, index) => {
            return (
              <span>
                {
                  text ?   <a-tooltip placement="topLeft" title={text.join(" ,")}>
                  {" "}
                  {text.join(" ,")}
                </a-tooltip> : '-'
                }
              
              </span>
            );
          },
        },
        {
          title: "同步目录",
          dataIndex: "dest",
          key: "dest",
          ellipsis: true,
          customRender: (text, row, index) => {
            return <span>{row.editable ?   <a-input
                    style="height: 28px"
                    value={text}
                    onBlur={(e) =>
                      this.changeTableDest(e.target.value, row.id, "dest")
                    }
                  /> : <div>
                    {text}
                    <span class="mgl12" onClick={() => this.edit(row.id)}>
                      <a-icon type="edit" />
                    </span>
                  </div> 
            }</span>;
          },
        },
        {
          title: "系统版本",
          key: "osrelease",
          dataIndex: "osrelease",
          width: 150,
        },
        {
          title: "CPU数量",
          key: "num_cpus",
          dataIndex: "num_cpus",
          width: 150,
        },
        {
          title: "内存大小",
          key: "mem_total",
          dataIndex: "mem_total",
          width: 150,
          customRender: (text, row, index) => {
            return <span>{text ? formatBytes(text * 1024 * 1024) : "-"}</span>;
          },
        },
        {
          title: "版本号",
          key: "saltversion",
          width: 150,
          dataIndex: "saltversion",
        },
        {
          title: "操作系统",
          key: "os",
          dataIndex: "os",
          customRender: (text, row, index) => {
            return <span>
              {text === "Linux" ? <svg-icon class="mgr8" style={{'font-size': '16px'}} icon-class="linux"></svg-icon> : <svg-icon class="mgr8" style={{'font-size': '16px'}} icon-class="windows"></svg-icon>}
              {text}
            </span>
          },
        },
      ],
      isFullScreen: false,
      code: "",
      currentTab: "1",
      unableClear: true,
    };
  },
  computed: {
    ...mapState({
      operationConsole: (state) => state.operationConsole,
    }),
    isHost() {
      const isHost = this?.operationConsole?.showItem?.scopedSlots?.title
      return isHost === "host"
    },
    markSelection() {
      const { selectedMarkRowKeys } = this;
      let _this = this;
      return {
        selectedMarkRowKeys,
        onSelectAll: (selectedRowKeys, selectedRows) => {
          _this.selectedMarkRows = selectedRows;
        },
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedMarkRowKeys = selectedRowKeys;
          _this.selectedMarkRows = selectedRows;
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );
        },
        getCheckboxProps: (record) => {
          return {
            props: {
              name: record.name,
            },
          };
        },
      };
    },
  },
  methods: {
    ...mapActions("runHistory", ["getHistoryList", "setRunningFlag", "setRunTab", "setNumAdd"]),
    onClose () {
      this.visible = false
      this.isFullScreen = false
    },
    // 执行命令
    runTask () {

    },
    groupChange() {
      this.getHostList();
    },
    markChange() {
      this.getTargetList()
    },
    callback(key) {
      this.currentTab = key;
    },
    changeTableDest(value, key, column) {
      const newData = this.isHost ? [...this.tableData] : [...this.targetData] 
      const target = newData.find((item) => key === item.id);
      if (target) {
        target[column] = value;
        target.editable = false
        this.setTable(newData)
      }
    },
    edit(key) {
      const newData = this.isHost ? [...this.tableData] : [...this.targetData] 
      const target = newData.find((item) => key === item.id);
      if (target) {
        target.editable = true;
        this.setTable(newData)
      }
    },
    getVal(val, filed) {
      this.params[`${filed}`] = val.target.value;
    },
    //   查询
    onSearch() {
      // 主机组纬度查询
      this.isHost ? this.getHostList() : this.getTargetList()
    },
    batchLabel() {
      if (this.selectedRows.length == 0) {
        this.$message.warning("请选择一条数据");
        return false;
      }
      var ids = [];
      this.selectedRows.map((item) => {
        ids.push(item.id);
      });
      const self = this;
      let width = 560;
      let title = "添加标签";
      let content = (
        <BatchLabel
          ids={ids}
          callBack={() => {
            self.onSearch();
            self.selectedRowKeys = [];
            self.selectedRows = [];
          }}
        />
      );
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    uploadHost() {
      const self = this;
      let width = 560;
      let title = "导入";
      let content = (
        <UploadFile
          callBack={() => {
            self.onSearch();
          }}
        />
      );
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    getMarkList() {
      const params = {
        page_size: 1000,
        page: 1,
        keyword: this.params.keyword || "",
      };
      let apiAjax = global.API.getMarkList + `/${showItemKey.split("group")[1]}/minions`
      this.$axiosGet(apiAjax, params).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          res.data.results.forEach((item) => {
            this.markList.push({
              id: item.id,
              name: item.name,
            });
          });
        }
      });
    },
    handleChange(value) {
      this.isBlur = false
      let result = value != null && value != "" ? value : undefined;
      this.dest = result;
        let tableData = this.isHost ? this.tableData : this.targetData
        tableData.forEach((child) => {
        const serviceArr = child.vars ? child.vars.filter((item) => item.name === this.dest): []
        let serviceValue = "/";
        if (serviceArr.length > 0) {
          serviceValue = serviceArr[0].value;
          child.dest = serviceValue;
        } else {
          child.dest = value
        }
        child.editable = false;
      });
      this.setTable(tableData)
    },
    handleSelect(value) {
      this.dest = value;
       let tableData = this.isHost ? this.tableData : this.targetData
        tableData.forEach((child) => {
        const serviceArr = child.vars ? child.vars.filter((item) => item.name === this.dest): []
        let serviceValue = "/";
        if (serviceArr.length > 0) {
          serviceValue = serviceArr[0].value;
          child.dest = serviceValue;
        } else {
          child.dest = serviceValue
        }
        child.editable = false;
      });
      this.setTable(tableData)
    },
    setTable (data) {
      if (this.isHost) {
        this.tableData = data
      } else {
        this.targetData = data
      }
    },
    syncFile(fileList) {
      const selcttargetList = this.selectedGroupRows;
      const selectRow = selcttargetList.filter((item) => !item.dest);
      if (selectRow.length > 0)
        return this.$message.warning("请完善目标文件中的同步目录");
      let syncs = [];
      fileList.forEach((val) => {
        let data = {
          src_type: "file",
          dest_type: "dir",
          tgt_type: "list",
        };
        selcttargetList.map((item) => {
          syncs.push({
            ...data,
            dest: item.dest,
            src: '/' + val.name,
            // tgt: this.isHost ? item.id + "" : item.nodename,
            tgt: item.id + "",
            client: this.execute,
            replace: this.replace,
            makedirs: this.makedirs,
          });
        });
      });
      let data = {
        client: this.client,
        replace: this.replace,
        makedirs: this.makedirs,
        syncs,
      };
      const that = this;
      setTimeout(() => {
        that.$refs.bottomOptRef.syncLoading = false
      }, 1500);
      this.$axiosJsonPost(global.API.quickFileSync + '?cluster_id=' + this.operationConsole.clusterId , data).then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          this.$refs.bottomOptRef.clearFileList()
          that.$message.success("提交成功");
          that.setRunningFlag(true);
          that.setRunTab("file");
          // 刷新列表 把分页置位1
          that.setNumAdd(true);
        }
      });
    },
    confirm(runObj) {
      let tgt = [];
      let order = [];
      let args = [];
      if (this.isHost && this.selectedGroupRows.length > 0 && !this.isAll) {
        this.selectedGroupRows.forEach((item) => {
          if(this.execute === 'ssh') {
            tgt.push(item.id + '');
          }  else {
            tgt.push(item.id + '');
          }
        });
      } else if (!this.isHost && this.selectedGroupRows.length > 0 && !this.isAll) {
        this.selectedGroupRows.forEach((item) => {
          tgt.push(item.nodename);
        });
      } else if (this.category && this.isAll) {
        order.push(this.category + "");
      } else {
        this.$message.warning("请至少选择一台主机");
        return;
      }
      for (let key in this.scriptParams) {
        args.push(this.scriptParams[key]);
      }
      let params = {
        client: this.execute,
        tgt_type: this.isAll
          ? this.isHost
            ? "manage_group"
            : "manage_target"
          : "list",
        tgt: this.isAll ? order : tgt,
        model: {
          lang: runObj.type || 'bash',
          code: runObj.code,
          timeout: 10,
          args: [],
        },
      };
      console.log(params);
      this.$refs.bottomOptRef.runLoading = true
      const that = this
      setTimeout(() => {
        this.$refs.bottomOptRef.runLoading = false
      }, 1500)
      this.$axiosJsonPost(
        global.API.syncNow + "?save2task=true&async=true" + '&cluster_id=' + this.operationConsole.clusterId,
        params
      ).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          this.setRunningFlag(true)
          this.$message.success("提交成功");
          let type = ''
          if (this.$refs.bottomOptRef.optTab === 'file') {
            type = 'file'
          } else {
            type = this.$refs.bottomOptRef.currentTab
          }
          if (type === 'script') type = 'cmd'
          this.setRunTab(type)
          // 刷新列表 把分页置位1
          this.setNumAdd(true)
        }
      });
    },
    fullScreen() {
      this.isFullScreen = true;
    },
    closeFullScreen() {
      this.isFullScreen = false;
    },
    getTargetList() {
      const _that = this
      this.markLoading = true
      const params = {
        page_size: 1000,
        page: 1,
        keyword: this.params.keyword || "",
      };
         // 初始化的时候获取所有主机展示
      const showItemKey = this.operationConsole.showItem.key
      let apiAjax = global.API.getTargets + `/${showItemKey.split("target")[1]}/minions`
      // let apiAjax = global.API.getTargets + `/all/minions`
      this.$axiosGet(apiAjax, params).then((res) => {
        this.markLoading = false;
        console.log(res);
        if ([200, 201, 204, 202].includes(res.status)) {
          const targetData = res.data ? res.data : []
          if (targetData.length > 0) targetData.forEach((item, index) => {
            item["index"] = index;
          });
          _that.handleAddHost(targetData)
          console.log(_that.targetData, 'tableDatatableDatatableData')
          // this.selectedMarkRowKeys = markData.map((item) => {
          //   return item.index;
          // })
          // this.selectedMarkRows = markData
        }   
      });
    },
    // 确定添加主机
    handleAddHost(val) {
      const tableData = val.map((value, index) => {
        let serviceValue = "/";
        const serviceArr = value.vars ? value.vars.filter((item) => item.name === this.dest) : []
        if (this.dest) {
          serviceValue = this.dest
        } else {
          if (serviceArr.length > 0) {
            serviceValue = serviceArr[0].value;
          }
        }
        return {
          dest: serviceValue,
          editable: false,
          ...value,
        };
      });
      let _that = this;
      if (_that.isHost) {
        _that.tableData = tableData
      } else {
        _that.targetData = tableData
      }
      _that.selectedRowKeys = val.map((x) => {
        return _that.isHost ? x.id : x.index;
      });
      _that.selectedRows = val;
    },
    getHostList() {
      if (!this.operationConsole.clusterId) return false
      this.groupLoading = true;
      const params = {
        page_size: 1000,
        page: 1,
        keyword: this.params.keyword || "",
        cluster_id: this.operationConsole.clusterId
      };
        // 初始化的时候获取所有主机展示
      const showItemKey = this.operationConsole.showItem.key
      let apiAjax = showItemKey.split("group")[1] === 'All' ? global.API.getHostList : global.API.getHostGroups + `/${showItemKey.split("group")[1]}/hosts`
      this.$axiosGet(apiAjax, params).then((res) => {
        this.groupLoading = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          const tableData = res.data.results ? res.data.results : []
          this.handleAddHost(tableData)
        }
      });
    },
    // 选择主机数据
    onSelectGroup(selectedRowKeys, selectedRows) {
      this.selectedGroupRowKeys = selectedRowKeys;
      this.selectedGroupRows = selectedRows;
    },
    // 选择目标数据
    onSelectMark(selectedRowKeys, selectedRows) {
      this.selectedMarkRowKeys = selectedRowKeys;
      this.selectedMarkRows = selectedRows;
    },
    // 获取目标路劲list
    getHostServiceList() {
      this.$axiosGet(global.API.getHostServiceList, {}).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          this.serviceList = res.data.filter((item) => item.name !== "")
          this.serviceList.map(item => {
            this.labelList.push(item.name)
          })
        }
      });
    },
  },
  mounted() {
    this.getHostServiceList()
    const showItem = this.operationConsole.showItem
    this.getHostList()
  },
};
</script>

<style lang="less" scoped>
 .run-task-drawer {
    /deep/ .ant-drawer-body {
      padding: 0 24px;
    }
    /deep/ .ant-drawer-close {
      width: 20px;
      height: 20px;
      right: 20px;
    }
    &.hasfullScreen {
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 9999;
      padding-top: 48px;
      /deep/ .jv-code {
        padding-top: 0;
      }
    }
    /deep/ .ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
      box-shadow: 0 -2px 10px rgba(0,0,0, 5%);
      border-top: 1px solid #cfd5de;
    }
  }
  .hasfullScreen {
      .run-task-page {
        .run-task-table {
        /deep/ .ant-table-content {
          max-height: calc(100vh - 64px)!important;
        }
      }
    }
  }
/deep/ .ant-btn {
  svg {
    margin-right: 4px;
  }
}
.action-content {
  display: flex;
  height: 100%;
  background: #fff;
  flex-direction: column;
  .content-header {
    position: absolute;
    top: 0;
    right: 0;
  }
  .body-left {
    position: relative;
    flex: 1;
    // padding: 0px 0 16px 0px;
    .script {
      padding-right: 28px;
      padding-left: 28px;
      height: 312px;
      overflow-y: auto;
      border-bottom: rgba(209, 210, 215, 1) 1px dashed;
      .label {
        font-size: 14px;
        color: #1d202d;
        margin-right: 8px;
      }
      .label-input {
        margin-right: 24px;
      }
      .save-btn {
        color: #2b95ff;
        border-color: #2b95ff;
      }
      .code {
        display: flex;
        margin-top: 12px;
        margin-bottom: 10px;
        .code-left {
          font-size: 14px;
          color: #1d202d;
          margin-right: 8px;
        }
        .code-right {
          flex: 1;
          width: 500px;
          background: #fff;
          padding-right: 0px;
          border: 1px solid rgba(207, 213, 222, 1);
          border-radius: 4px;
          .btns {
            height: 33px;
            // width: 980px;
            // border-bottom: none;
            box-shadow: 0px 1px 0px 0px rgba(231, 234, 239, 1);
            background: #fff;
            border-radius: 4px;
            border-bottom: 1px solid #cfd5de;
            .tab {
              cursor: pointer;
              font-size: 14px;
              color: #6C7079;
              display: inline-block;
              padding: 0px 20px;
              text-align: center;
              height: 29px;
              line-height: 29px;
              background: #fff;
            }
            .active-btn {
              color: #3974f4;
              border-top: 3px solid #3974f4;
              background: #fff;
              height: 35px;
              // border-bottom: 1px solid #cfd5de;
              border-right: 1px solid #cfd5de;
              border-left: 1px solid #cfd5de;
            }
            #editor {
              // width: 980px !important;
              height: 167px !important;
            }
          }
        }
      }
      .parameter {
        margin-bottom: 16px;
      }
    }
    .table {
      margin-top: 12px;
      // padding: 0 24px;
      .search-card {
        margin-bottom: 12px;
        box-shadow: none;
        margin-right: 0px;
        padding: 0 12px;
        .search-select {
          margin-right: 10px;
        }
        .executeWay {
          margin-right: 12px;
        }
        .checkAll {
          margin-right: 12px;
        }
        .checkbox {
          margin-right: 24px;
        }
      }
  
      .table-card {
        margin-bottom: 12px;
        box-shadow: none;
        padding: 0 12px;
      }
    }
  }
  .switch-action  {
    /deep/ .ant-switch-inner {
      font-size: 14px;
    }
  }
  /deep/ .ant-table-content {
    height: calc(100vh - 444px) !important;
    overflow-y: auto;
    padding-right: 0px;
    overflow-x: hidden;
  }
  /deep/ .ant-empty-normal {
    margin: 0;
  }
}
</style>
