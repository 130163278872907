<!--
 * @Author: mjzhu
 * @Date: 2022-06-08 11:38:30
 * @LastEditTime: 2022-09-07 23:24:07
 * @FilePath: \awx-ui\src\pages\hostManage\components\addGroup.vue
-->
<template>
  <div style="padding-top: 10px">
    <a-form
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      :form="form"
      class="p0-32-10-32 form-content"
    >
      <a-form-item label="命令名称">
        <a-input
          v-decorator="[
            'name',
            { rules: [{ required: true, message: '命令名称不能为空!' }] },
          ]"
          placeholder="请输入命令名称"
        />
      </a-form-item>
      <!-- <a-form-item label="用户名">
        <a-input type="username" v-decorator="['username',{ rules: [{ required: true, message: '用户名不能为空!' }] }]" placeholder="请输入用户名" />
      </a-form-item>
      <a-form-item label="密码">
        <a-input type="password" v-decorator="['password',{ rules: [{ required: !editFlag, message: '密码不能为空!' }] }]" placeholder="请输入密码" />
      </a-form-item> -->
      <!-- <a-form-item label="端口号">
        <a-input v-decorator="['port',{ rules: [{ required: true, message: '端口号不能为空!' }] }]" placeholder="请输入端口号，如：22" />
      </a-form-item> -->
      <a-form-item label="描述">
        <a-input
          v-decorator="[
            'desc',
            { rules: [{ required: false, message: '描述不能为空!' }] },
          ]"
          type="textarea"
          placeholder="请输入描述"
        />
      </a-form-item>
    </a-form>
    <div class="ant-modal-confirm-btns-new">
      <a-button
        style="margin-right: 10px"
        type="primary"
        @click.stop="handleSubmit"
        :loading="loading"
        >确认</a-button
      >
      <a-button @click.stop="formCancel">取消</a-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: function () {
        return {};
      },
    },
    hostGroupId: Number,
    callBack: Function,
  },
  data() {
    return {
      editFlag: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
      },
      form: this.$form.createForm(this),
      loading: false,
    };
  },
  watch: {},
  methods: {
    checkName(rule, value, callback) {
      var reg = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g;
      if (reg.test(value)) {
        callback(new Error("名称中不能包含中文"));
      }
      if (/\s/g.test(value)) {
        callback(new Error("名称中不能包含空格"));
      }
      callback();
    },
    formCancel() {
      this.$destroyAll();
    },
    handleSubmit(e) {
      const _this = this;
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log(values);
        if (!err) {
          const params = {
            name: values.name,
            desc: values.desc,
          };
          this.loading = true;
          this.$axiosPatch(global.API.getOrderList + `/${this.detail.id}`, params)
              .then((res) => {
                this.loading = false;
                if ([200, 201, 204, 202].includes(res.status)) {
                  this.$message.success("保存成功", 2);
                  this.$destroyAll();
                  _this.callBack();
                }
              })
              .catch((err) => {});
            return false;
        }
      })
    },
    echoHostDetail() {
      if (JSON.stringify(this.detail) !== "{}") {
        this.editFlag = true;
        this.$axiosGet(global.API.getHostGroups + `/${this.detail.id}`).then(
          (res) => {
            console.log(res, "sjiku");
            this.form.getFieldsValue([
              "name",
              "username",
              "port",
              "passord",
              "desc",
            ]);
            this.form.setFieldsValue({
              name: this.detail.name,
              ip: this.detail.ip,
              password: this.detail.password && this.detail || '',
              port: this.detail.port,
              desc: this.detail.desc,
              username: this.detail.username,
            });
          }
        );
      } else {
        this.editFlag = false;
        this.form.getFieldsValue([
          "desc",
          "passord",
          "name",
          "username",
          "port",
        ]);
        this.form.setFieldsValue({
          name: "",
          password: "",
          port: "22",
          username: "",
          desc: "",
        });
      }
    },
  },
  mounted() {
    this.echoHostDetail();
  },
};
</script>
<style lang="less" scoped>
</style>
