<!--
 * @Author: mjzhu
 * @Date: 2022-06-13 14:04:05
 * @LastEditTime: 2024-03-28 18:09:33
 * @FilePath: \awx-ui\src\pages\operationConsole\components\bottomOpe\index..vue
-->
<template>
  <div class="bottom-ope" style="position: relative;">
    <div class="file-center-footer">
      <div class="center-footer-options">
        <div>
          <a-popover overlayClassName="water-popover" title trigger="click">
            <template #content>
              <waterFall :currentTab="currentTab" :waterList="waterList" @getCode="getCode" @addNew="addNew">
                <template slot="footer">
                  <div class="btn-footer">
                    <div :class="['btn-item', currentTab ==='script' && 'btn-item-active']" @click="changeCurrentTab('script')">脚本</div>
                    <div :class="['btn-item', currentTab ==='cmd' && 'btn-item-active']" @click="changeCurrentTab('cmd')">命令</div>
                    <div :class="['btn-item', currentTab ==='task' && 'btn-item-active']" @click="changeCurrentTab('task')">任务</div>
                    <div class="btn-add" @click="addNew">+</div>
                  </div>
                </template>
              </waterFall>
            </template>
            <a-button class="ope-btn" @click="changOptTab('script')" type="link" title="脚本">
              <svg-icon icon-class="ope-script"></svg-icon>
            </a-button>
          </a-popover>
          <a-popover overlayClassName="water-popover" title trigger="click">
            <template #content>
              <waterFall :currentTab="currentTab" :waterList="waterList" @getCode="getCode" @addNew="addNew">
                <template slot="footer">
                  <div class="btn-footer">
                    <div :class="['btn-item', currentTab ==='script' && 'btn-item-active']" @click="changeCurrentTab('script')">脚本</div>
                    <div :class="['btn-item', currentTab ==='cmd' && 'btn-item-active']" @click="changeCurrentTab('cmd')">命令</div>
                    <div :class="['btn-item', currentTab ==='task' && 'btn-item-active']" @click="changeCurrentTab('task')">任务</div>
                    <div class="btn-add" @click="addNew">+</div>
                  </div>
                </template>
              </waterFall>
            </template>
            <a-button class="ope-btn" @click="changOptTab('cmd')" type="link" title="命令">
              <svg-icon icon-class="ope-cmd"></svg-icon>
            </a-button>
          </a-popover>
          <a-popover overlayClassName="water-popover" title trigger="click">
            <template #content>
              <upload ref="UploadFileRef" @getFileList="getFileList" @changeSyncLoading="changeSyncLoading" />
            </template>
            <a-button class="ope-btn" @click="changOptTab('file')" type="link" title="文件">
              <svg-icon icon-class="ope-task"></svg-icon>
            </a-button>
          </a-popover>
        </div>
        <a-dropdown :trigger="['click']">
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            {{ getLabel(runObj.lang) }} <a-icon type="down" />
          </a>
          <a-menu slot="overlay" style="height: 180px; overflow-y: auto;">
            <a-menu-item :key="item.key" v-for="(item) in langList" @click="changeLang(item)">
              {{ item.label }}
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
      <div v-if="optTab === 'run'">
        <a-textarea v-model="runObj.code" class="bottom-textarea" placeholder="请输入内容" :rows="8" />
      </div>
      <div v-else>
        <uploadList :fileList="fileList" :selectedGroupRows="selectedGroupRows" @uploadFile="uploadFile" />
      </div>
      <div class="btn-run">
        <a-button v-if="optTab === 'run'" type="primary" :loading="runLoading" @click="runTask">
          <svg-icon class="btn-run-svg" icon-class="ope-run"></svg-icon>立即执行
        </a-button>
        <a-button v-else type="primary" :loading="syncLoading" @click="syncFile">
          <svg-icon class="btn-run-svg" icon-class="ope-sync"></svg-icon>立即同步
        </a-button>
      </div>
    </div>
  </div>
</template>
<script>
import waterFall from "./waterfall.vue";
import upload from "./upload.vue";
import uploadList from "./uploadList.vue";
import AddCmd from './addCmd.vue'

export default {
  components: { waterFall, upload, uploadList },
  props: {
    currentHistory: Object,
    resultLoading: Boolean,
    resultData: Array,
    resultColumns: Array,
    selectedGroupRows: Array,
    isFullScreen: {
      type: Boolean,
      default: false,
    },
  },
  inject: ['fileSync'],
  data() {
    return {
      syncLoading: false,
      langList: [
        {
          label: "Bash",
          key: "bash",
        },
        {
          label: "Shell",
          key: "sh",
        },
        {
          label: "Python2",
          key: "python2",
        },
        {
          label: "Python3",
          key: "python3",
        },
        {
          label: "Ruby",
          key: "ruby",
        },
        {
          label: "Perl",
          key: "perl",
        },
        {
          label: "PowerShell",
          key: "powershell",
        },
      ],
      scriptList: [],
      cmdList: [],
      currentTab: "script",
      optTab: "run",
      runLoading: false,
      fileList: [],
      waterList: [],
      runObj: {
        code: "",
        lang: 'bash'
      },
    };
  },
  mounted() {
    this.getCmdList();
    this.getScriptList()
  },
  methods: {
    addNew () {
      if (this.currentTab === 'task') return this.$message.warning('敬请期待')
      const self = this;
      let width = 700;
      let title = this.currentTab === "script" ? '添加脚本' : this.currentTab === "cmd" ? "添加命令" : "添加任务";
      let content = (
        <AddCmd currentTab={this.currentTab} callBack={() => {
          self.currentTab === "script" ? self.getScriptList() : self.currentTab === "cmd" ? self.getCmdList() : console.log('task');
          }} />
      );
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    changeSyncLoading (flag) {
      this.syncLoading = flag
    },
    changeLang (item) {
      this.runObj.lang = item.key
    },
    getLabel (key) {
      const arr = this.langList.filter(item => item.key === key)
      let label = ''
      if (arr.length) label = arr[0].label
      return label
    },
    getCode (code, item) {
      if (item['lang']) {
        this.runObj.lang = item['lang']
      } else {
        this.runObj.lang = 'bash'
      }
      this.runObj.code = code
    },
    getFileList(fileList) {
      this.fileList = fileList;
    },
    changOptTab(type) {
      if (type !== "file") this.currentTab = type;
      if (type === 'cmd') {
        this.$set(this, 'waterList', this.cmdList)
      }
      if (type === 'script') {
        this.$set(this, 'waterList', this.scriptList)
      }
      if (type === 'task') this.waterList = []
      this.optTab = type === "file" ? "file" : "run";
    },
    changeCurrentTab(type) {
      this.currentTab = type;
      if (type === 'cmd') this.waterList = this.cmdList
      if (type === 'script') this.waterList = this.scriptList
      if (type === 'task') this.waterList = []
    },
    fullScreen() {
      this.$emit("fullScreen");
    },
    onClose() {
      this.$emit("onClose");
    },
    closeFullScreen() {
      this.$emit("closeFullScreen");
    },
    runTask() {
      if (!this.runObj.code) return this.$message.warning("请输入需要执行的内容");
      this.$emit("confirm", this.runObj);
    },
    syncFile() {
      if (!this.fileList.length) return this.$message.warning("请至少选择一个文件");
      if (!this.selectedGroupRows.length) {
       return this.$message.warning("请至少选择一台主机");
      }
      this.syncLoading = true
      this.hostsImport()
      // this.$refs.UploadFileRef.hostsImport()
    },
    uploadFile (file, index) {
      const formData = new FormData();
      formData.append("file", file);
      file.loading = true;
      file.progress = 0;
      file.status = 'notUpload'
      this.$set(this.fileList, index, file)
      let timer = null
      timer = setInterval(()=>{
        if (file.progress>=80){
          file.progress = 90;
          this.$set(this.fileList, index, file)
          clearInterval(timer)
        }else{
          file.progress+=20
          this.$set(this.fileList, index, file)
        }
      }, 500);
      this.$axiosPutFormData(
        global.API.uploadFile + "/",
        formData
      ).then((res) => {
        file.progress = 100
        if ([200, 201, 202, 204].includes(res.status)) {
          file.status = "success";
          this.$set(this.fileList, index, file)
        } else {
          file.status = "fail";
          this.$set(this.fileList, index, file)
        }
      })
      .catch((res) => {
        file.progress = 100
        file.status = "fail";
        this.$set(this.fileList, index, file)
      });
    },
    hostsImport() {
      const {fileList} = this
      let promiseArr = [];
      // const fileFloderName = moment(Date()).format("YYYYMMDD");
      fileList.forEach((file, index) => {
        if (file.status === 'success') return false
        const formData = new FormData();
        formData.append("file", file);
        file.loading = true;
        this.$set(this.fileList, index, file)
        let timer = null
        timer = setInterval(()=>{
          if (file.progress>=80){
            file.progress = 90;
            this.$set(this.fileList, index, file)
            clearInterval(timer)
          }else{
            file.progress+=20
            this.$set(this.fileList, index, file)
          }
        }, 500);
        let p = null;
        p = new Promise((resolve) => {
          this.$axiosPutFormData(
            global.API.uploadFile + "/",
            formData
          )
            .then((res) => {
              file.progress = 100
              if ([200, 201, 202, 204].includes(res.status)) {
                file.status = "success";
                this.$set(this.fileList, index, file)
              } else {
                file.status = "fail";
                this.$set(this.fileList, index, file)
              }
              resolve();
            })
            .catch((res) => {
              file.progress = 100
              file.status = "fail";
              this.$set(this.fileList, index, file)
            });
        });
        if (p) promiseArr.push(p);
      });
      Promise.all(promiseArr).then((res) => {
        let arr = this.fileList.filter(item => item.status === 'success')
        if (arr.length === this.fileList.length) {
          setTimeout(() => {
            this.fileSync(this.fileList)
          }, 500)
        } else {
         this.changeSyncLoading()
        }
      });
    },
    clearFileList() {
      this.$refs.UploadFileRef.clearFileList()
    },
    // 根据指定属性值将一维数组进行分组成二维数组
    mapTwoDimension(arr) {
      let dataArr = [];
      if (arr.length === 0) {
        return dataArr;
      }
      arr.map((oneDim) => {
        if (dataArr.length == 0) {
          dataArr.push({
            ...oneDim,
            children: [oneDim],
          });
        } else {
          let res = dataArr.some((item) => {
            // 判断相同，有就添加到当前项
            if (item.lang == oneDim.lang) {
              item.children.push(oneDim);
              return true;
            }
          });
          if (!res) {
            // 如果没找相同添加一个新对象
            dataArr.push({
              ...oneDim,
              children: [oneDim],
            });
          }
        }
      });
      return dataArr;
    },
    getCmdList() {
      const params = {
        page_size: 1000,
      };
      this.$axiosGet(global.API.getOrderList, params).then((res) => {
        this.treeSpain = false;
        let dataArr = [];
        if (res.data.results && res.data.results.length > 0) {
          dataArr = res.data.results;
          dataArr.forEach((item) => {
            if (!item["lang"]) item["lang"] = "bash";
          });
          this.cmdList = this.mapTwoDimension(dataArr);
        } else {
          this.cmdList = []
        }
      })
    },
    getScriptList() {
      const params = {
        // page_size: 1000,
      };
      this.$axiosProxyGet(global.API.getProxyScript, params).then((res) => {
        this.treeSpain = false;
        if (res.data && res.data.length > 0) {
          let dataArr = [];
            dataArr = res.data;
            dataArr.forEach((item) => {
              if (!item["lang"]) item["lang"] = "bash";
            });
            this.scriptList = this.mapTwoDimension(dataArr);
        } else {
          this.scriptList = []
        }
      })
    },
  },
};
</script>
<style lang="less" scoped>
  .hasfullScreen {
    .bottom-ope {
      .run-task-table {
        /deep/ .ant-table-content {
          max-height: calc(100vh - 64px) !important;
        }
      }
    }
  }
  .bottom-ope {
    height: 290px;
    padding: 0 0 0 0;
    .center-footer-options {
      height: 34px;
      display: flex;
      background-image: linear-gradient(179deg, #fbfcfe 0%, #f0f5ff 100%);
      box-shadow: 0px 1px 0px 0px rgba(219, 230, 255, 1);
      border-top: 1px solid rgba(207, 213, 222, 1);
      align-items: center;
      padding-right: 20px;
      justify-content: space-between;
      padding-left: 6px;
      .ope-btn {
        padding: 0 6px;
        // margin: 0 12px;
        font-size: 16px;
        border: none;
        svg {
          font-size: 22px;
          margin-right: 0px;
        }
        &:hover {
          background: #e6e8ed;
        }
      }
    }
    .bottom-textarea {
      border: none !important;
      resize: none;
      outline: none !important;
      border-color: transparent !important;
      box-shadow: none !important;
    }
    .btn-run {
      display: flex;
      justify-content: flex-end;
      margin-right: 12px;
      padding-right: 12px;
      width: 100%;
      padding-top: 16px;
      button {
        width: 120px;
      }
      &-svg {
        font-size: 14px;
        position: relative;
        top: -1px;
        left: -2px;
      }
    }
  }
</style>
<style>
  .water-popover {
    z-index: 999;
  }
</style>